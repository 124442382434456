import axios from '@/plugins/axios';

export const generateUrl = async (): Promise<any> => new Promise((resolve, reject) => {
  axios.post('auth/telegram/key/link')
    .then((res) => {
      resolve(res.data);
    })
    .catch((e) => {
      reject(e.response.data);
    });
});
