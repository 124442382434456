import { authCode } from './authCode';
import { checkAuth } from './checkAuth';
import { fetchUnreadMessages } from './fetchUnreadMessages';
import { generateToken } from './generateToken';
import { generateUrl } from './generateUrl';
import { getUserProfile } from './getUserProfile';
import { login } from './login';
import { logout } from './logout';
import { register } from './register';
import { telegramAuth } from './telegramAuth';
import { unsubscribe } from './unsubscribe';

export const actions = {
  authCode,
  checkAuth,
  fetchUnreadMessages,
  generateToken,
  generateUrl,
  getUserProfile,
  login,
  logout,
  register,
  telegramAuth,
  unsubscribe,
};
