import { defineStore } from 'pinia';
import { IState } from './types';
import { state } from './state';
import { actions } from './actions';

export const useCommonStore = defineStore('common', {
  state: (): IState => state,
  // actions: actions as IActions,
  actions,
});
