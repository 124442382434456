import axios, { AxiosInstance } from 'axios';

const headers = {
  'Content-type': 'application/json',
};

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers,
};

const apiClient: AxiosInstance = axios.create(options);

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  // config.headers.lang = localStorage.getItem('lang');
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const responseError = error;
    let result = null;
    const originalRequest = error.config;

    const exeptions = [
      '/auth/token',
    ];
    if (error.response.status === 401 && !originalRequest.retry && exeptions.indexOf(originalRequest.url) === -1) {
      originalRequest.retry = true;
      if (localStorage.getItem('refreshToken')) {
        try {
          const accessToken = await refreshAccessToken();
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          result = apiClient(originalRequest);
        } catch (e) {
          result = Promise.reject(error);
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          window.location.href = '/auth/login';
        }
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        if (window.location.href !== '/auth/login') {
          window.location.href = '/auth/login';
        }
      }
    } else {
      result = Promise.reject(error);
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('refreshToken');
      // if (window.location.href !== '/auth/login') {
      //   window.location.href = '/auth/login';
      // }
    }
    return result;
  },
);

const refreshAccessToken = async (): Promise<any> => {
  try {
    const token = localStorage.getItem('refreshToken');
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/token/refresh`, { refresh_token: token });
    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    return response;
  } catch (e: any) {
    throw new Error(e);
  }
};

export default apiClient;
