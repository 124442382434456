import axios from '@/plugins/axios';
import { useAuthStore } from '..';

export const getUserProfile = async (): Promise<any> => {
  const authStore = useAuthStore();
  return new Promise((resolve, reject) => {
    axios.get('dashboard/profile')
      .then((res) => {
        authStore.profile = res.data.data;
        resolve(true);
      })
      .catch((e) => {
        // localStorage.removeItem('accessToken');
        reject(e.response.data);
      });
  });
};
