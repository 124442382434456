import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      isGlobalPage: true,
    },
    redirect: { name: 'Login' },
    component: () => import('@/templates/TemplateAuth/TemplateAuth.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/PageLogin/PageLogin.vue'),
      },
      {
        path: 'registration',
        name: 'Registration',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/PageRegistration/PageRegistration.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Main',
    redirect: {
      name: 'Scanners',
    },
  },
  {
    path: '/scanners/',
    name: 'Scanners',
    component: () => import('@/pages/scanners/PageScanners/PageScanners.vue'),
  },
  {
    path: '/scanners/new/',
    name: 'ScannerNew',
    component: () => import('@/pages/scanners/PageScannerNew/PageScannerNew.vue'),
  },
  {
    path: '/scanners/:scannerId/',
    redirect: (to) => ({ name: 'ScannerData', params: { scannerId: to.params.scannerId, page: 1 } }),
  },
  {
    path: '/scanners/:scannerId/:page/',
    name: 'ScannerData',
    component: () => import('@/pages/scanners/PageScannerData/PageScannerData.vue'),
  },
  {
    path: '/scanners/:scannerId/:page/:leadId/',
    name: 'ScannerLeadsInfo',
    component: () => import('@/pages/scanners/PageScannerData/PageScannerData.vue'),
  },
  {
    path: '/leads/',
    name: 'Leads',
    redirect: {
      name: 'LeadsList',
      params: {
        page: 1,
      },
    },
  },
  {
    path: '/leads/:page/',
    name: 'LeadsList',
    component: () => import('@/pages/PageLeads/PageLeads.vue'),
  },
  {
    path: '/leads/:page/:leadId/',
    name: 'LeadsInfo',
    component: () => import('@/pages/PageLeads/PageLeads.vue'),
  },
  // {
  //   path: '/referals',
  //   name: 'Referals',
  //   component: () => import('@/pages/PageReferals/PageReferals.vue'),
  // },
  {
    path: '/profile/',
    name: 'Profile',
    component: () => import('@/pages/PageProfile/PageProfile.vue'),
  },
  {
    path: '/payment/',
    name: 'Subscriptions',
    component: () => import('@/pages/PageSubscriptions/PageSubscriptions.vue'),
    children: [
      {
        path: 'thank-you/',
        name: 'ThankYou',
        component: () => import('@/pages/PageThankYou/PageThankYou.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: any, from: any, next: any) => {
  if (to?.query?.token) {
    localStorage.setItem('accessToken', to.query.token.toString());
  }
  if (localStorage.getItem('accessToken') && (to.name === 'Login')) {
    useAuthStore().getUserProfile()
      .then(() => {
        next({ name: 'Scanners' });
      })
      .catch(() => {
        next();
      });
    next({ name: 'Scanners' });
  } else if (!to.meta.isGlobalPage && !useAuthStore().profile) {
    if (localStorage.getItem('accessToken')) {
      useAuthStore().getUserProfile()
        .then(() => {
          next();
        })
        .catch(() => {
          next({ name: 'Login' });
        });
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});
export default router;
