import { createI18n } from 'vue-i18n';
import messagesRu from '@/assets/i18n/ru.json';

const messagesLang = {
  ...messagesRu,
};

const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: messagesLang,
});

export default i18n;
