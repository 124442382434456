import axios from '@/plugins/axios';
import { ILoginData } from '../types';

export const register = async (data: ILoginData): Promise<any> => (
  new Promise((resolve, reject) => {
    axios.post('/auth/register', data)
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  })
);
