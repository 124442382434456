import router from '@/router';
import { useAuthStore } from '../index';

export const logout = ():void => {
  const authStore = useAuthStore();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  authStore.profile = null;
  router.push({ name: 'Login' });
};
