import axios from '@/plugins/axios';

export const generateToken = async (): Promise<any> => new Promise((resolve, reject) => {
  axios.post('/profile/token/generate')
    .then((res) => {
      resolve(res.data.data.token);
    })
    .catch((e) => {
      reject(e.response.data);
    });
});
