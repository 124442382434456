import axios from '@/plugins/axios';

export const fetchUnreadMessages = async (): Promise<any> => new Promise((resolve, reject) => {
  axios.get('dashboard/profile')
    .then((res) => {
      resolve(res.data.data.counter.notViewedLeads);
    })
    .catch((e) => {
      reject(e.response.data);
    });
});
