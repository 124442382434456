import axios from '@/plugins/axios';
import { ITelegramAuthData } from '@/types/auth/ITelegramAuthData';

export const telegramAuth = async (data: ITelegramAuthData): Promise<any> => (
  new Promise((resolve, reject) => {
    axios.post('/auth/telegram', data)
      .then((res) => {
        localStorage.setItem('accessToken', res.data.token);
        localStorage.setItem('refreshToken', res.data.refresh_token);
        resolve(true);
      })
      .catch((e) => {
        reject(e?.response?.data);
      });
  })
);
