import axios from '@/plugins/axios';
import { ILoginData } from '../types';

export const login = async (data: ILoginData): Promise<any> => (
  new Promise((resolve, reject) => {
    axios.post('/auth/token', data)
      .then((res) => {
        localStorage.setItem('accessToken', res.data.token);
        localStorage.setItem('refreshToken', res.data.refresh_token);
        resolve(true);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  })
);
